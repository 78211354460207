import React from "react"
import PropTypes from "prop-types"
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'

import "../../css/components/slider.scss"

const SliderArrow = ({className, to, onClick, left}) => (
    <div onClick={onClick} className={`sr-slider-arrow ${className}`} aria-label={to}>
        {left ? <FaChevronCircleLeft icon={to} /> : <FaChevronCircleRight icon={to} /> }
    </div>
)

SliderArrow.defaultProps = {
    left: true,
}

SliderArrow.propTypes = {
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    left: PropTypes.bool,
}

export default SliderArrow
