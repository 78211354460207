import React from "react"
import {Link} from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "../../css/pages/couverture.scss"
import Layout from "../../components/Layout/layout"
import SEO from "../../components/seo"
import Section from "../../components/section"
import YellowButton from "../../components/Buttons/yellowButton"
import CouvertureImg from "../../images/card.jpg";
import SliderArrow from "../../components/Slider/sliderArrow";

const Chantier1 = () => {
    const images = [
        <div>
            <img src={CouvertureImg} className={"img-slider"}/>
        </div>,
        <div>
            <img src={CouvertureImg} className={"img-slider"}/>
        </div>,
        <div>
            <img src={CouvertureImg} className={"img-slider"}/>
        </div>,
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <SliderArrow to={"prev"}/>,
        nextArrow: <SliderArrow to={"next"} left={false}/>,
    }

    return (
        <Layout>
            <SEO title="Couverture"/>
            <div className={"page couverture"}>
                <div className={"header"}>
                    <h1 className={"title"}>Chantier 1</h1>
                </div>

                <Section>
                    <p className={"subtitle"}>
                        L’entreprise Athies Batiment réalise tous vos travaux de couverture. Nos artisans qualifiés et
                        formés réaliseront votre projet selon vos besoins. Nous intervenons principalement dans l’Aisne
                        (02) et dans la Marne (51), mais nous pouvons aussi nous déplacer dans d'autres secteurs. Pour
                        cela, contactez-nous : <Link to={"/#contact"}>cliquez-ici</Link>.
                    </p>
                </Section>

                <section>
                    <h2>Photos du chantier</h2>

                    <Slider {...settings}>
                        {images.map(image => image)}
                    </Slider>
                </section>

                <YellowButton className={"cta"} link={"/#contact"}>Nous contacter</YellowButton>
            </div>
        </Layout>
    )
}

export default Chantier1
